<!--
 * @Author: your name
 * @Date: 2020-11-24 12:17:18
 * @LastEditTime: 2021-05-10 09:54:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\adminInverter.vue
-->
<template>
    <div class="adminInverter-main-box">
        <!-- <div class="search-box">
            <div class="search-box-sub">
                <span class="lable-text">{{$t('heatpump.data-type')}}</span>
                <el-input v-model="heatPumpType" style="width:160px;" :placeholder="$t('common.info.pl-insert-content')"></el-input>
            </div>
            
            <div class="search-box-sub">
                <el-button type="primary" @click='query'>{{$t('common.button.query')}}</el-button>
            </div>
        </div> -->
        <div class="button-box">
            <el-button type="primary" class="marginR10" icon='el-icon-plus' @click="addDevice">{{$t('project.Equipment-add')}}</el-button>
            <el-popconfirm
                    :title="$t('common.button.del-bath-confirm')"
                    @confirm='theatpumpDeleteBatch()'
            >
                <el-button slot="reference" icon='el-icon-delete' class="marginR10" >{{$t('link.delete')}}</el-button>
            </el-popconfirm>
            <!-- <el-button type="primary" icon='el-icon-upload2' class="pull-right" @click="dialogVisible = true">{{$t('system.import')}}</el-button> -->
        </div>
        <!-- :data="dataRows" -->
        <baseTable v-loading="loading"  row-key='sid'  :data="dataRows" border  stripe   :column='column' :select='true' @selection-change="handleSelectionChange($event)" >
            <template #deviceCode='{data}'>
                <a href="javascript:;" style="color: #4c98d9;" @click='pushEditUrl(data)'>{{deviceCodeName(data)}}</a>
            </template>
            <template #deviceTypeCode='{data}'>
                <a href="javascript:;" style="color: #4c98d9;" @click='pushEditUrl(data)'>{{deviceTypeCodeName(data)}}</a>
            </template>
            <template #option="{data}">
                <div class="option-box">
                    <el-popconfirm
                            :title="$t('common.button.del-bath-confirm')" @confirm='theatpumpDelete(data.id)'>
                        <i class="el-icon-delete" slot="reference"></i>
                    </el-popconfirm>
                </div>
            </template>
        </baseTable>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <!--导入弹出框-->
        <el-dialog
                :title="$t('project.select-device')"
                :visible.sync="dialogVisible"
                width="30%"
                v-if='dialogVisible'
        >   
            <el-form ref="form" :model="form" label-width="170px">
                <el-form-item :label="$t('project.Equipment-type')">
                    <el-select v-model="form.deviceCode" placeholder="" style="width:70%" @change="changeDevice(form.deviceCode)" >
                        <el-option
                            v-for="item in DictionaryBatch.OTHER_DEVICE_TYPES"
                            :key="item.value"
                            :label="item.code"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('project.Equipment-classification')">
                    <el-select v-model="form.deviceTypeCode" placeholder="" style="width:70%" @change="changeDeviceTypeCode()">
                        <el-option
                            v-for="item in deviceTypeList"
                            :key="item.value"
                            :label="item.code"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            
            
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('system.button.cancel')}}</el-button>
                <el-button type="primary" :loading="loadingBtn" :disabled="disabled" @click="adddeviceData()">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import baseTable from '../../components/common/baseTable'
import baseFile from '../../components/common/baseFileInputNew'
export default {
    components: {
        baseTable,
        baseFile
    },
    props: {

    },
    data() {
        return {
            uploadFile:'',
            dialogVisible: false,
            heatPumpId:[],
            heatPumpType:'',
            name:'',
            loading:false,
            loadingBtn:false,
            dataRows:[],
            column:[{
                title: this.$t('project.Equipment-type'),
                value: 'name',
                slot: 'deviceCode'
            },{
                title: this.$t('project.Equipment-classification'),
                value: 'dryContact',
                slot: 'deviceTypeCode'
            },{
                title: this.$t('common.msg.opeation'),
                value: 'option',
                slot: 'option'
            }],
            size:10,
            current:1,
            total:1,
            DictionaryBatch:{},
            deviceTypeList:[],
            form:{
                deviceCode:'',
                deviceTypeCode:''
            },
            disabled:true
        };
    },
    computed: {
     
    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.otherdeviceGetPage();
        this.tdictionaryGetDictionaryBatch()
    },
    methods: {
        deviceCodeName(code){
            let codeName 
            (this.DictionaryBatch.OTHER_DEVICE_TYPES||[]).forEach((item=>{
                if (item.value == code.deviceCode) {
                    codeName=  item.code
                }
            }))
            return codeName
        },
        deviceTypeCodeName(code){
            let codeName,codeType
           (this.DictionaryBatch.OTHER_DEVICE_TYPES||[]).forEach((item=>{
                if (item.value == code.deviceCode) {
                    codeName=  item.code
                }
            }));
            (this.DictionaryBatch[codeName]||[]).forEach((deviceTypeCode)=>{
                if (deviceTypeCode.value == code.deviceTypeCode) {
                    codeType=  deviceTypeCode.code
                }
            })
            return codeType
        },
        /*列表分页*/
        async otherdeviceGetPage(){
            this.loading = true
            let res = await this.API.otherdeviceGetPage({
                current:this.current,
                size:this.size,
            })
            this.dataRows = res.rows
            this.total= parseInt(res.total)
            this.loading = false
        },

        /*删除*/
        async theatpumpDelete(id){
            this.heatPumpId = []
            this.heatPumpId.push(id)
            this.theatpumpDeleteBatch()
        },

        /*批量删除*/
        async theatpumpDeleteBatch(){
            let res = await this.API.otherdeviceDeleteBatch({
                ids:this.heatPumpId
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.otherdeviceGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },


        /*跳转详情页面*/
        pushEditUrl(data){
            this.$router.push({path: '/addDevice', query:{deviceTypeCode:data.deviceTypeCode,deviceCode:data.deviceCode,id:data.id}});
            sessionStorage.setItem("paramJson",data.paramJson)
        },
        async tdictionaryGetDictionaryBatch() {
            let res = await this.API.tdictionaryGetDictionaryBatch({
                lang: localStorage.getItem("lan"),
                dictCodes: [
                "METER_TYPES",
                "DATAHUB_TYPES",
                "LAN_TYPES",
                "4G_TYPES",
                "WIFI_TYPES",
                "EPS_BOX_TYPES",
                "BMS_BOX_TYPES",
                "MATE_BOX_TYPES",
                "OTHER_DEVICE_TYPES",
                // "DATAHUB_TYPES_PARAM",
                // "METER_TYPES_PARAM",
                // "LAN_TYPES_PARAM",
                // "4G_TYPES_PARAM",
                // "WIFI_TYPES_PARAM",
                // "EPS_BOX_TYPES_PARAM",
                // "MATE_BOX_TYPES_PARAM",
                ],
            });
            this.DictionaryBatch = res;
        },

        // 添加设备
        addDevice(){
            this.form.deviceCode = ''
            this.form.deviceTypeCode = ''
            this.dialogVisible = true
            // ()=>{$router.push({path:'/addHeatPump'})}
        },
        changeDevice(val){
            console.log(val,"val");
            this.DictionaryBatch.OTHER_DEVICE_TYPES.forEach(item => {
                if (item.value == val) {
                    this.GetDictionaryBatch(item.code)
                }
            });
        },
        changeDeviceTypeCode(){
            this.disabled = false
        },
        adddeviceData(){
            this.$router.push({path:'/addDevice',query:{deviceTypeCode:this.form.deviceTypeCode,deviceCode:this.form.deviceCode}})
            sessionStorage.setItem("paramJson",'')
            let codeName
            this.DictionaryBatch.OTHER_DEVICE_TYPES.forEach((item=>{
                if (item.value == this.form.deviceCode) {
                    codeName=  item.code
                }
                
            }))
            codeName = codeName + "_PARAM"
            console.log(codeName);
            sessionStorage.setItem("deviceCode",codeName)
        },
         async GetDictionaryBatch(item) {
            this.deviceTypeList =[]
            this.deviceTypeCode=''
            let res = await this.API.tdictionaryGetDictionaryBatch({
                lang: localStorage.getItem("lan"),
                dictCodes: [item],
            });
            this.deviceTypeList = res[item];
        },
        query(){
            this.current = 1
            this.otherdeviceGetPage()
        },
        
        handleSelectionChange(val){
          this.heatPumpId=val.map( item=>{
            return item.id
          })
        },
        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.otherdeviceGetPage()
        },
        handleCurrentChange(val) {
            this.current = val
            this.otherdeviceGetPage()
        },
    },
};
</script>

<style  lang="less" scoped>

</style>
